<!--
 * @Author: your name
 * @Date: 2021-04-20 15:44:16
 * @LastEditTime: 2021-05-20 01:07:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\shareresume2\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation></navigation>
    <div class="topmsges">
      <div class="topmsg">
        <div>推荐简历</div>
        <div></div>
      </div>
      <div class="buzh">
        <div class="hengxian"></div>
        <div class="dianxu">
          <div>1</div>
          <div>2</div>
          <div>3</div>
        </div>
      </div>
    </div>
    <div class="themsg">
      <div
        class="mcl2"
      >
        <div>意向行业<span class="xh">*</span></div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <div @click="hymeth" :style="industry ? 'color:black' : ''" class="xxx1">
          {{ industry ? industry : "请选择意向行业" }}
        </div>
        <!-- <input
          readonly="readonly"
          v-model="industry"
          type="text"
          placeholder="请选择意向行业"
        /> -->
      </div>
      <div @click="showshow = true" class="mcl2">
        <div>求职岗位</div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="allmsg.jop"
          type="text"
          placeholder="请选择求职岗位"
        />
      </div>
      <div @click="clickyixiang(3)" class="mcl2">
        <div>学历<span class="xh">*</span></div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="allmsg.education_background"
          type="text"
          placeholder="请选择学历"
        />
      </div>
      <div class="mcl2">
        <div>
          年龄<span style="color: #ff4800" v-show="!isage"
            >(年龄格式不正确)</span
          >
        </div>
        <input
          v-model="age"
          class="oncl"
          type="number"
          placeholder="请输入您的年龄"
        />
      </div>
      <div class="mcl2">
        <div>户籍所在地</div>
        <input
          v-model="allmsg.household_register"
          class="oncl"
          type="text"
          placeholder="请填写户籍所在地"
        />
      </div>
      <div class="mcl2">
        <div>
          联系邮箱<span style="color: #ff4800" v-show="!isemail"
            >(邮箱格式不正确)</span
          >
        </div>
        <input
          v-model="email"
          class="oncl"
          type="text"
          placeholder="请输入您的邮箱"
        />
      </div>
      <!-- <div class="mcl2">
        <div>教育院校及专业</div>
        <input
          v-model="allmsg.school_major"
          class="oncl"
          type="text"
          placeholder="请输入教育院校及专业"
        />
      </div> -->
      <!-- <div class="mcl2">
        <div @click="$router.push('/school')" class="yuancla">
          <div>教育院校及专业</div>
          <van-icon color="#FF5F19" size=".4rem" name="add-o" />
        </div>
        <div v-for="(item, index) in schoolobj" v-bind:key="index" class="s1">
          <div class="s2">
            <div>{{ item.school }}</div>
            <div>{{ item.start_time }} 至 {{ item.end_time }}</div>
          </div>
          <div class="s3">{{ item.education_background }}-{{ item.major }}</div>
        </div>
      </div> -->
      <div @click="clickyixiang(4)" class="mcl2">
        <div>性别<span class="xh">*</span></div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="allmsg.sex"
          type="text"
          placeholder="请选择您的性别"
        />
      </div>
      <div @click="clickyixiang(5)" class="mcl2">
        <div>政治面貌</div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="allmsg.politics"
          type="text"
          placeholder="请选择政治面貌"
        />
      </div>
      <div @click="clickyixiang(6)" class="mcl2">
        <div>工作年限</div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="allmsg.years_working"
          type="text"
          placeholder="请选择工作年限"
        />
      </div>
      <div @click="clickyixiang(7)" class="mcl2">
        <div>期望薪资（元）</div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="allmsg.salary"
          type="text"
          placeholder="请选择您的期望薪资"
        />
      </div>
      <div @click="$refs.addressid.addressmeth('2', '')" class="mcl2">
        <div>求职区域</div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="area"
          type="text"
          placeholder="请选择您的求职区域"
        />
      </div>
    </div>
    <!--弹窗数据-->
    <van-popup v-model="showyixiang" round position="bottom">
      <van-picker
        :title="titlemsg"
        show-toolbar
        :columns="yixiangarray"
        @cancel="showyixiang = false"
        @confirm="yixiangmeth"
      />
    </van-popup>
    <div class="xieyi">
      <van-checkbox checked-color="#FBA110" v-model="checked"
        >同意告知候选人您已将其简历推荐至简历公海，帮他谋求更好的未来
        <span @click.stop="showduan = true">查看短信内容</span></van-checkbox
      >
    </div>
    <van-popup class="showduanclass" v-model="showduan">
      <img src="../../assets/duan.png" alt="" />
    </van-popup>
    <div class="fenxiang">
      <button @click="nextmeth">下一步</button>
    </div>
    <div class="nomsgclass"></div>
    <addresses ref="addressid" @addressreturn="addressreturn"></addresses>
    <van-popup
      v-model="showshow"
      round
      position="bottom"
      :style="{ height: '100%' }"
    >
      <postselect></postselect>
    </van-popup>
    <industrys ref="industrysid" @confirm="confirm"></industrys>
    <van-popup
      v-model="stationshow"
      round
      position="bottom"
      :style="{ height: '100%' }"
    >
       <station></station>
    </van-popup>
   
  </div>
</template>
<script>
import addresses from "../../components/address";
import eventbus from "../../../util/eventbus.js";
import postselect from "../../components/postselect/index.vue";
import industrys from "../../components/industry/index.vue";
import station from "../../components/station/index.vue";
import education from "../../../util/education";
import expectedSalary from "../../../util/expectedSalary";
import workingYears from "../../../util/workingYears"
export default {
  components: {
    addresses,
    postselect,
    industrys,
    station
  },
  data() {
    return {
      showshow: false,
      education_background: "",
      jop: "",
      area: "",
      schoolobj: "",
      checked: false,
      showduan: false,

      showyearwork: false, //是否展示工作年限

      titlemsg: "",

      allmsg: "", //数据
      industry: "", //意向行业

      yixiangarray: [], //意向行业数组
      showyixiang: false, //选择意向行业
      clickindex: "", //点击下标
      yixiangarray2: [],

      email: "", //邮箱
      isemail: true,
      age: "", //邮箱
      isage: true,

      okup: false, //是否可以提交
      stationshow:false,

      schoolarray: [
        {
          school: "", //学校名称
          education_background: "", //学历
          major: "", //专业
          start_time: "", //入学时间
          end_time: "", //毕业时间
        },
      ],
    };
  },
  watch: {
    email() {
      if (
        /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(
          this.email
        ) &&
        this.email
      ) {
        this.isemail = true;
        this.okup = true;
      } else {
        if (!this.email) {
          this.isemail = true;
          this.okup = true;
        } else {
          this.isemail = false;
          this.okup = false;
        }
      }
    },
    age() {
      if (this.age > 65 || this.age < 18) {
        this.isage = false;
        this.okup = false;
      } else {
        this.isage = true;
        this.okup = true;
      }
    },
  },
  created() {
    this.allmsg = JSON.parse(this.$route.query.msg);
    //期望薪资
    if (!this.allmsg.salary) {
      this.allmsg.salary = "面议";
    }
    this.email = this.allmsg.email; //邮箱
    this.age = this.allmsg.age; //年龄
    this.area = this.allmsg.area ? this.allmsg.area("/") : this.allmsg.area;
    this.industry = this.allmsg.industry ? this.allmsg.industry : ""; //意向行业
    if (this.allmsg.politics == "") {
      this.allmsg.politics = "群众";
    }
    if (this.allmsg.salary == "") {
      this.allmsg.salary = "面议";
    }
    this.getyix();
    //求职岗位返回的值
    eventbus.$on("config", (e) => {
      this.showshow = false;
      this.$set(this.allmsg, "jop", e);
    });
    eventbus.$on("cancel", () => {
      this.showshow = false;
    });
    eventbus.$on("cancelstation",()=>{
      this.stationshow = false;
    });
    eventbus.$on("cancelstation",()=>{
      this.stationshow = false;
    });
    eventbus.$on("confirmstation",(e)=>{
      this.stationshow = false;
      this.industry = e;
    })
  },
  methods: {
    //意向行业
    confirm(msg){
      this.industry = msg;
    },
    hymeth(){
      this.stationshow = true;
    },
    //地址返回值
    addressreturn(msg, index) {
      this.area = msg[0].name + "/" + msg[1].name;
    },
    addschmeth() {
      this.$set(this.schoolarray, this.schoolarray.length, {
        school: "", //学校名称
        education_background: "", //学历
        major: "", //专业
        start_time: "", //入学时间
        end_time: "", //毕业时间
      });
    },
    closeschmeth() {
      this.$delete(this.schoolarray, this.schoolarray.length - 1);
    },
    //事件
    yixiangmeth(msg) {
      switch (this.clickindex) {
        case 1:
          this.allmsg.industry = msg;
          break;
        // case 2:
        //   this.jop = msg;
        //   break;
        case 3:
          this.allmsg.education_background = msg;
          break;
        case 4:
          this.allmsg.sex = msg;
          break;
        case 5:
          this.allmsg.politics = msg;
          break;
        case 6:
          this.allmsg.years_working = msg;
          break;
        case 7:
          this.allmsg.salary = msg;
          break;
      }
      this.showyixiang = false;
    },
    clickyixiang(index) {
      this.clickindex = index;
      switch (index) {
        case 1:
          this.yixiangarray = this.yixiangarray2;
          this.titlemsg = "意向行业";
          break;
        case 3:
          this.yixiangarray = education;
          this.titlemsg = "学历";
          break;
        case 4:
          this.yixiangarray = ["男", "女"];
          this.titlemsg = "性别";
          break;
        case 5:
          this.yixiangarray = ["群众", "党员", "民主党派"];
          this.titlemsg = "政治面貌";
          break;
        case 6:
          this.yixiangarray = workingYears;
          this.titlemsg = "工作年限";
          break;
        case 7:
          this.yixiangarray = expectedSalary;
          this.titlemsg = "期望薪资";
          break;
      }
      this.showyixiang = true;
    },
    onback() {},
    getyix() {},
    nextmeth() {
      if (
        !this.industry ||
        !this.allmsg.education_background ||
        !this.allmsg.sex
      ) {
        this.$toast("请完善必填信息");
        return;
      }
      if (this.checked == false) {
        this.$toast("请同意告知候选人");
        return;
      }
      if (!this.okup) {
        this.$toast("请修改年龄和邮箱信息");
        return;
      }
      this.$http
        .post("/firm/v1/resume/second_add", {
          reqType: "resume",
          email: this.email,
          school_major: this.allmsg.schoolarray,
          household_register: this.allmsg.household_register,
          id: this.allmsg.id,
          mobile: this.allmsg.mobile,
          salary: this.allmsg.salary,
          id_no: this.allmsg.id_no,
          jop: this.allmsg.jop,
          education_background: this.allmsg.education_background,
          sex: this.allmsg.sex,
          years_working:
            this.allmsg.years_working == "应届"
              ? "0"
              : this.allmsg.years_working == "20年以上"
              ? 20
              : this.allmsg.years_working,
          industry: this.industry,
          politics: this.allmsg.politics,
          checked: this.allmsg.checked,
          name: this.allmsg.name,
          note: this.allmsg.note,
          share_type: this.allmsg.share_type,
          age: this.age,
          area: this.area && this.area.split("/"),
        })
        .then((res) => {
          this.$router.push("/shareresume3");
        });
    },
  },
};
</script>
<style scoped>
.xxx1 {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.48rem;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  color: #676767;
}
.allmsgclass >>> .van-popup--bottom.van-popup--round {
  border-radius: 0;
}
.showduanclass img {
  width: 4rem;
}
.xieyi {
  margin: 0.38rem;
  font-size: 0.25rem;
}
.xieyi span {
  color: #ff5b1e;
  margin-left: 0.3rem;
  font-weight: bold;
}
.s2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.13rem;
  margin-top: 0.15rem;
}
.s2 > :first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
.s2 > :nth-child(2) {
  color: #333333;
  font-size: 0.2rem;
  line-height: 0.26rem;
}
.s3 {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #333333;
}
.yuancla {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addclose {
  display: flex;
  align-items: center;
}
.addclose > :first-child {
  margin-right: 0.3rem;
}
.addclose > :nth-child(2) {
  background-color: #f55613;
  border: 1px solid #f55613;
}
.addschoolclass {
  display: flex;
  align-items: center;
  border: 1px solid #38f;
  width: 1.5rem;
  justify-content: center;
  background-color: #38f;
  color: #ffffff;
  border-radius: 0.1rem;
  margin-top: 0.3rem;
  padding: 0.05rem;
}
.scclas {
  margin-bottom: 0.3rem;
  font-size: 0.3rem;
}
.smsg {
  display: flex;
  align-items: center;
}
.smsg input {
  border: none;
  width: 2.5rem;
}
.smsg > :nth-child(2) {
  border-bottom-color: #697386;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: 0.3rem;
  padding: 0.1rem 0;
  width: 2.5rem;
}
.xh {
  color: #ff1f1f;
  font-size: 0.3rem;
  margin-left: 0.05rem;
}
.themsg {
  margin: 0 0.38rem;
}
.hangyclass {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem;
}
.sh1c {
  margin: 0.2rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  background: #f7f7f7;
  border-radius: 0.08rem;
  padding: 0.21rem 0.24rem;
}
.selehangclass {
  margin: 0.2rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.42rem;
  background: #ffffff;
  border-radius: 0.08rem;
  padding: 0.18rem 0.21rem;
  color: #ff5f19;
  border: 0.03rem solid #ff5f19;
}
.rgc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mcl2 {
  padding: 0.42rem 0;
  border-bottom-color: #d6d6d6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.28rem;
}
.mcl2 input {
  border: none;
}
.mcl2 > :first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
}
.mcl2 > input {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.48rem;
  width: 100%;
}
.oncl {
  margin-top: 0.15rem;
}
.msgclass {
  margin: 0.38rem 0.4rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.setding {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.nomsgclass {
  height: 1.5rem;
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 95%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.29rem 2.5%;
}
.fenxiang > button:first-child {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border: 0.03rem solid #ff5f19;
  border-radius: 0.16rem;
  padding: 0.25rem 0;
  text-align: center;
  min-width: 90%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  margin-left: 50%;
  transform: translateX(-50%);
}
.fenxiang > button:nth-child(2) {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  padding: 0.28rem 0;
  text-align: center;
  width: 45%;
}
.tipmsg {
  padding: 0.15rem 0.4rem;
  background: #fff3ee;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.33rem;
  margin-bottom: 0.3rem;
}

.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.topmsges {
  background-color: #ffffff;
  padding-bottom: 1.3rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.38rem;
  padding-top: 0.16rem;
  background-color: #ffffff;
}
.topmsg > :first-child {
  font-size: 0.46rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.65rem;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.buzh {
  margin: 0 1.12rem;
  position: relative;
  margin-top: 0.39rem;
  background-color: #ffffff;
}
.hengxian {
  height: 0.08rem;
  background: linear-gradient(270deg, #ffe2d5 0%, #ff5f19 100%);
  position: absolute;
  width: 100%;
  top: 0.16rem;
  z-index: 1;
}
.dianxu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.38rem;
}
.dianxu > div {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #ff5f19;
  z-index: 2;
}
.dianxu > div:nth-child(3) {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #ffe2d5;
  z-index: 2;
}
.dianxu > div:first-child::after {
  content: "上传简历";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.38rem;
}
.dianxu > div:nth-child(2):after {
  content: "完善简历";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.38rem;
}
.dianxu > div:nth-child(3):after {
  content: "完成简历推荐";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #6f6f6f;
  line-height: 0.38rem;
}
</style>
